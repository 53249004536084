<template>
  <div id="invoice-page">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="mgnit-logo" width="50" class="mx-auto">
    </div>
    <template v-else>
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center ml-auto">
          <!-- <vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button> -->
          <!-- <vs-button class="d-none ml-4" @click.prevent="flaskPrint">
            <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 stroke-current" />
          </vs-button> -->
          <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-printer" @click="flaskPrint"><span class="text-primary">.</span></vs-button>
          <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">{{ $t('print') }}</vs-button>
        </div>
      </div>
      <vx-card id="invoice-container" style="margin-bottom: 0;">
        <div class="vx-row p-4">
          <div class="vx-col w-1/2">
            <img src="@/assets/images/logo/logo.png" class="w-32" alt="magnit-logo">
            <!-- <h1>MAGNIT</h1> -->
            <div class="invoice__recipient-info mb-2 mt-4">
              <h5 class="mb-2">{{ $t('recipient') }}: {{ order.user_name | capitalize }}</h5>
              <p>
                <feather-icon icon="MapPinIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2"> {{ order.address }}</span>
              </p>
              <p class="flex items-center">
                <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2"> +993 {{ order.user_phone }}</span>
              </p>
            </div>
          </div>
          <div class="vx-col w-1/2 text-right">
            <h3>{{ $t('invoice') }}: #{{ order.code }}</h3>
            <div class="invoice__invoice-detail mt-6">
              <div class="inline">
                <h6>{{ $t('date') }}</h6>
                {{ new Date(order.createdAt).toLocaleString('ru-RU') }}
              </div>
              <h6 class="mt-4">{{ $t('deliveryTime') }}</h6>
              <p>{{ order.delivery_time }}</p>
            </div>
          </div>
          <!-- <div class="vx-col w-1/2">
            <h5>{{ $t('recipient') }}</h5>
            
          </div> -->
          <!-- <div class="vx-col w-1/2 mt-base text-right mt-12"> -->
            <!-- <h5>{{ 'magnit.com.tm' }}</h5> -->
            <!-- <div class="invoice__company-contact">
              <p class="flex items-center justify-end">
                  <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ 'hello@magnit.com.tm' }}</span>
              </p>
              <p class="flex items-center justify-end">
                  <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ '+993 65656666' }}</span>
              </p>
            </div> -->

          <!-- </div> -->
        </div>
        <div class="p-4" v-if="order.order_products.length || order.order_collections.length">
          <vs-table hoverFlat :data="order.order_products">
            <template slot="thead">
              <vs-th class="pointer-events-none">#</vs-th>
              <vs-th class="pointer-events-none">{{ $t('fields.bar_code') }} / {{ $t('fields.code') }}</vs-th>
              <!-- <vs-th class="pointer-events-none"></vs-th> -->
              <vs-th class="pointer-events-none">{{ $t('fields.shelf_code') }}</vs-th>
              <vs-th class="pointer-events-none">{{ $t('fields.title') }}</vs-th>
              <vs-th class="pointer-events-none">{{ $t('fields.price') }}</vs-th>
              <vs-th class="pointer-events-none">{{ $t('qt') }}</vs-th>
              <!-- <vs-th class="pointer-events-none">{{ $t('discount.d') | capitalize }}</vs-th> -->
              <vs-th class="pointer-events-none">{{ $t('fields.total') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(tr, index) in data" :key="index">
                <vs-td class="py-2">{{ index + 1 }}</vs-td>
                <vs-td class="py-2">{{ data[index].bar_code }} / {{ data[index].code }}</vs-td>
                <!-- <vs-td class="py-2">{{ data[index].bar_code }}</vs-td> -->
                <vs-td class="py-2" :data="data[index].task">{{ data[index].shelf_code }}</vs-td>
                <vs-td class="py-2" :data="data[index].task">
                  {{ data[index][`name_${$i18n.locale}`] }}
                  {{ data[index].weight }}
                  <template v-if="data[index]['Orderproducts'].discount_amount || data[index]['Orderproducts'].discount_required">
                    ({{ data[index]['Orderproducts'].discount_type !== 'bonused' ? -data[index]['Orderproducts'].discount_amount : `${data[index]['Orderproducts'].discount_required} + ${data[index]['Orderproducts'].discount_bonus}`}}
                    {{ data[index]['Orderproducts'].discount_type === 'price' ? 'm' : data[index]['Orderproducts'].discount_type === 'percentage' || data[index]['Orderproducts'].discount_type === 'promo' ? '%' : ''}})
                  </template>
                </vs-td>
                <vs-td class="py-2" :data="data[index].rate">{{ data[index]['Orderproducts'].price.toFixed(2) }}m</vs-td>
                <vs-td class="py-2" :data="data[index].hours">{{ data[index]['Orderproducts'].quantity }}</vs-td>
                <!-- <vs-td class="py-2 font-medium" :data="data[index].rate">
                  {{ data[index]['Orderproducts'].discount_type !== 'bonused' ? -data[index]['Orderproducts'].discount_amount : `${data[index]['Orderproducts'].discount_required} + ${data[index]['Orderproducts'].discount_bonus}`}}
                  {{ data[index]['Orderproducts'].discount_type === 'price' ? 'm' : data[index]['Orderproducts'].discount_type === 'percentage' || data[index]['Orderproducts'].discount_type === 'promo' ? '%' : ''}}
                </vs-td> -->
                <vs-td class="py-2" :data="data[index].amount">{{ data[index]['Orderproducts'].total_price.toFixed(2) }}m</vs-td>
              </vs-tr>
              <vs-tr v-for="(tr, index) in order.order_collections" :key="'c' + index">
                <vs-td class="py-2">{{ index + 1 + order.order_products.length }}</vs-td>
                <vs-td class="py-2">{{ order.order_collections[index].bar_code }} / {{ order.order_collections[index].code }}</vs-td>
                <vs-td class="py-2">{{ order.order_collections[index].shelf_code }}</vs-td>
                <vs-td class="py-2" :data="order.order_collections[index].task">
                  <feather-icon icon="ArchiveIcon" svgClasses="pt-1 w-5 h-5 stroke-current" />
                  {{ order.order_collections[index][`name_${$i18n.locale}`] }} {{ order.order_collections[index].weight }}
                  <template v-if="order.order_collections[index]['Ordercollections'].discount_amount || order.order_collections[index]['Ordercollections'].discount_required">
                    ({{ order.order_collections[index]['Ordercollections'].discount_type !== 'bonused' ? -order.order_collections[index]['Ordercollections'].discount_amount : `${order.order_collections[index]['Ordercollections'].discount_required} + ${order.order_collections[index]['Ordercollections'].discount_bonus}`}}
                    {{ order.order_collections[index]['Ordercollections'].discount_type === 'price' ? 'm' : order.order_collections[index]['Ordercollections'].discount_type === 'percentage' ? '%' : ''}})
                  </template>
                </vs-td>
                <vs-td class="py-2" :data="order.order_collections[index].rate">{{ order.order_collections[index]['Ordercollections'].price.toFixed(2) }}m</vs-td>
                <vs-td class="py-2" :data="order.order_collections[index].hours">{{ order.order_collections[index]['Ordercollections'].quantity }}</vs-td>
                <!-- <vs-td class="py-2 font-medium" :data="order.order_collections[index].rate">
                  {{ order.order_collections[index]['Ordercollections'].discount_type !== 'bonused' ? -order.order_collections[index]['Ordercollections'].discount_amount : `${order.order_collections[index]['Ordercollections'].discount_required} + ${order.order_collections[index]['Ordercollections'].discount_bonus}`}}
                  {{ order.order_collections[index]['Ordercollections'].discount_type === 'price' ? 'm' : order.order_collections[index]['Ordercollections'].discount_type === 'percentage' ? '%' : ''}}
                </vs-td> -->
                <vs-td class="py-2" :data="order.order_collections[index].amount">{{ order.order_collections[index]['Ordercollections'].total_price.toFixed(2) }}m</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-table hoverFlat class="position-absolute w-1/2 mt-4 ml-auto" :data="[]">
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('fields.subtotal') }}</span></vs-th>
              <vs-td class="py-2">{{ subtotal }}m</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('delivery') }}</span></vs-th>
              <vs-td class="py-2">{{ order.delivery_cost.toFixed(2) }}m</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('discount.d') }}</span></vs-th>
              <vs-td class="py-2">-{{ discount }}m</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('fields.total') }}</span></vs-th>
              <vs-td class="py-2 font-bold">{{ order.total_price.toFixed(2) }}m</vs-td>
            </vs-tr>
          </vs-table>
        </div>
        <div class="invoice__footer text-right p-4">
          <p class="mb-4">{{ new Date().getFullYear() }} &copy; Magnit Market. Tel: +99362193322 Site: magnit.com.tm</p>
          <!-- <p>
              <span class="mr-8">BANK: <span class="font-semibold">FTSBUS33</span></span>
              <span>IBAN: <span class="font-semibold"> G882-1111-2222-3333 </span></span>
          </p> -->
        </div>
      </vx-card>
      <vx-card id="second-invoice-container" style="display: none; margin-bottom: 0;">
        <div class="vx-row p-4">
          <div class="vx-col w-1/2">
            <img src="@/assets/images/logo/logo.png" class="w-32" alt="magnit-logo">
            <!-- <h1>MAGNIT</h1> -->
            <div class="invoice__recipient-info mb-2 mt-4">
              <h5 class="mb-2">{{ $t('recipient') }}: {{ order.user_name | capitalize }}</h5>
              <p>
                <feather-icon icon="MapPinIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2"> {{ order.address }}</span>
              </p>
              <p class="flex items-center">
                <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2"> +993 {{ order.user_phone }}</span>
              </p>
            </div>
          </div>
          <div class="vx-col w-1/2 text-right">
            <h3>{{ $t('invoice') }}: #{{ order.code }}</h3>
            <div class="invoice__invoice-detail mt-6">
              <div class="inline">
                <h6>{{ $t('date') }}</h6>
                {{ new Date(order.createdAt).toLocaleString('ru-RU') }}
              </div>
              <h6 class="mt-4">{{ $t('deliveryTime') }}</h6>
              <p>{{ order.delivery_time }}</p>
            </div>
          </div>
          <!-- <div class="vx-col w-1/2">
            <h5>{{ $t('recipient') }}</h5>
            
          </div> -->
          <!-- <div class="vx-col w-1/2 mt-base text-right mt-12"> -->
            <!-- <h5>{{ 'magnit.com.tm' }}</h5> -->
            <!-- <div class="invoice__company-contact">
              <p class="flex items-center justify-end">
                  <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ 'hello@magnit.com.tm' }}</span>
              </p>
              <p class="flex items-center justify-end">
                  <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ '+993 65656666' }}</span>
              </p>
            </div> -->

          <!-- </div> -->
        </div>
        <div class="p-4" v-if="order.order_products.length || order.order_collections.length">
          <vs-table hoverFlat :data="order.order_products">
            <template slot="thead">
              <vs-th class="pointer-events-none">#</vs-th>
              <!-- <vs-th class="pointer-events-none">{{ $t('fields.bar_code') }} / {{ $t('fields.code') }}</vs-th> -->
              <!-- <vs-th class="pointer-events-none"></vs-th> -->
              <!-- <vs-th class="pointer-events-none">{{ $t('fields.shelf_code') }}</vs-th> -->
              <vs-th class="pointer-events-none">{{ $t('fields.title') }}</vs-th>
              <vs-th class="pointer-events-none">{{ $t('fields.price') }}</vs-th>
              <vs-th class="pointer-events-none">{{ $t('qt') }}</vs-th>
              <!-- <vs-th class="pointer-events-none">{{ $t('discount.d') | capitalize }}</vs-th> -->
              <vs-th class="pointer-events-none">{{ $t('fields.total') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr v-for="(tr, index) in data" :key="index">
                <vs-td class="py-2">{{ index + 1 }}</vs-td>
                <!-- <vs-td class="py-2">{{ data[index].bar_code }} / {{ data[index].code }}</vs-td> -->
                <!-- <vs-td class="py-2">{{ data[index].bar_code }}</vs-td> -->
                <!-- <vs-td class="py-2" :data="data[index].task">{{ data[index].shelf_code }}</vs-td> -->
                <vs-td class="py-2" :data="data[index].task">
                  {{ data[index][`name_${$i18n.locale}`] }}
                  {{ data[index].weight }}
                  <template v-if="data[index]['Orderproducts'].discount_amount || data[index]['Orderproducts'].discount_required">
                    ({{ data[index]['Orderproducts'].discount_type !== 'bonused' ? -data[index]['Orderproducts'].discount_amount : `${data[index]['Orderproducts'].discount_required} + ${data[index]['Orderproducts'].discount_bonus}`}}
                    {{ data[index]['Orderproducts'].discount_type === 'price' ? 'm' : data[index]['Orderproducts'].discount_type === 'percentage' || data[index]['Orderproducts'].discount_type === 'promo' ? '%' : ''}})
                  </template>
                </vs-td>
                <vs-td class="py-2" :data="data[index].rate">{{ data[index]['Orderproducts'].price.toFixed(2) }}m</vs-td>
                <vs-td class="py-2" :data="data[index].hours">{{ data[index]['Orderproducts'].quantity }}</vs-td>
                <!-- <vs-td class="py-2 font-medium" :data="data[index].rate">
                  {{ data[index]['Orderproducts'].discount_type !== 'bonused' ? -data[index]['Orderproducts'].discount_amount : `${data[index]['Orderproducts'].discount_required} + ${data[index]['Orderproducts'].discount_bonus}`}}
                  {{ data[index]['Orderproducts'].discount_type === 'price' ? 'm' : data[index]['Orderproducts'].discount_type === 'percentage' || data[index]['Orderproducts'].discount_type === 'promo' ? '%' : ''}}
                </vs-td> -->
                <vs-td class="py-2" :data="data[index].amount">{{ data[index]['Orderproducts'].total_price.toFixed(2) }}m</vs-td>
              </vs-tr>
              <vs-tr v-for="(tr, index) in order.order_collections" :key="'c' + index">
                <vs-td class="py-2">{{ index + 1 + order.order_products.length }}</vs-td>
                <vs-td class="py-2">{{ order.order_collections[index].bar_code }} / {{ order.order_collections[index].code }}</vs-td>
                <vs-td class="py-2">{{ order.order_collections[index].shelf_code }}</vs-td>
                <vs-td class="py-2" :data="order.order_collections[index].task">
                  <feather-icon icon="ArchiveIcon" svgClasses="pt-1 w-5 h-5 stroke-current" />
                  {{ order.order_collections[index][`name_${$i18n.locale}`] }} {{ order.order_collections[index].weight }}
                  <template v-if="order.order_collections[index]['Ordercollections'].discount_amount || order.order_collections[index]['Ordercollections'].discount_required">
                    ({{ order.order_collections[index]['Ordercollections'].discount_type !== 'bonused' ? -order.order_collections[index]['Ordercollections'].discount_amount : `${order.order_collections[index]['Ordercollections'].discount_required} + ${order.order_collections[index]['Ordercollections'].discount_bonus}`}}
                    {{ order.order_collections[index]['Ordercollections'].discount_type === 'price' ? 'm' : order.order_collections[index]['Ordercollections'].discount_type === 'percentage' ? '%' : ''}})
                  </template>
                </vs-td>
                <vs-td class="py-2" :data="order.order_collections[index].rate">{{ order.order_collections[index]['Ordercollections'].price.toFixed(2) }}m</vs-td>
                <vs-td class="py-2" :data="order.order_collections[index].hours">{{ order.order_collections[index]['Ordercollections'].quantity }}</vs-td>
                <!-- <vs-td class="py-2 font-medium" :data="order.order_collections[index].rate">
                  {{ order.order_collections[index]['Ordercollections'].discount_type !== 'bonused' ? -order.order_collections[index]['Ordercollections'].discount_amount : `${order.order_collections[index]['Ordercollections'].discount_required} + ${order.order_collections[index]['Ordercollections'].discount_bonus}`}}
                  {{ order.order_collections[index]['Ordercollections'].discount_type === 'price' ? 'm' : order.order_collections[index]['Ordercollections'].discount_type === 'percentage' ? '%' : ''}}
                </vs-td> -->
                <vs-td class="py-2" :data="order.order_collections[index].amount">{{ order.order_collections[index]['Ordercollections'].total_price.toFixed(2) }}m</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-table hoverFlat class="position-absolute w-full mt-4 ml-auto" :data="[]">
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('fields.subtotal') }}</span></vs-th>
              <vs-td class="py-2">{{ subtotal }}m</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('delivery') }}</span></vs-th>
              <vs-td class="py-2">{{ order.delivery_cost.toFixed(2) }}m</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('discount.d') }}</span></vs-th>
              <vs-td class="py-2">-{{ discount }}m</vs-td>
            </vs-tr>
            <vs-tr>
              <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('fields.total') }}</span></vs-th>
              <vs-td class="py-2 font-bold">{{ order.total_price.toFixed(2) }}m</vs-td>
            </vs-tr>
          </vs-table>
        </div>
        <div class="invoice__footer text-right p-4">
          <p class="mb-4">{{ new Date().getFullYear() }} &copy; Magnit Market. Tel: +99362193322 Site: magnit.com.tm</p>
          <!-- <p>
              <span class="mr-8">BANK: <span class="font-semibold">FTSBUS33</span></span>
              <span>IBAN: <span class="font-semibold"> G882-1111-2222-3333 </span></span>
          </p> -->
        </div>
      </vx-card>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
export default{
  data () { return { mailTo: '', order: {}, isLoading: true } },
  async created () { await this.fetchData() },
  computed: {
    discount () {
      let total = 0
      this.order.order_products.forEach(el => {
        if (el['Orderproducts'].discount_type) {
          switch (el['Orderproducts'].discount_type) {
          case 'bonused':
            total += (Math.floor(el['Orderproducts'].quantity / (el['Orderproducts'].discount_required + el['Orderproducts'].discount_bonus)) * el['Orderproducts'].discount_bonus) * el['Orderproducts'].price
            break
          case 'price':
            total += el['Orderproducts'].discount_amount * el['Orderproducts'].quantity
            break
          case 'percentage':
            total += (((el['Orderproducts'].price * 100) * el['Orderproducts'].discount_amount * 10) / 100000)
            break
          case 'promo':
            total += ((el['Orderproducts'].price * el['Orderproducts'].discount_amount) / 100) * el['Orderproducts'].quantity
            break
          }
        }
      })
      this.order.order_collections.forEach(el => {
        if (el['Ordercollections'].discount_type) {
          switch (el['Ordercollections'].discount_type) {
          case 'bonused':
            total += el['Ordercollections'].discount_bonus * el['Ordercollections'].price
            break
          case 'price':
            total += el['Ordercollections'].discount_amount * el['Ordercollections'].quantity
            break
          case 'percentage':
            total += ((((el['Ordercollections'].total_price / el['Ordercollections'].quantity) * 100) * el['Ordercollections'].discount_amount) / (100 - el['Ordercollections'].discount_amount) / 100) * el['Ordercollections'].quantity
            break
          case 'promo':
            total += ((((el['Ordercollections'].total_price / el['Ordercollections'].quantity) * 100) * el['Ordercollections'].discount_amount) / 100 / 100) * el['Ordercollections'].quantity
            break
          }
        }
      })
      return total.toFixed(2)
    },
    subtotal () {
      let subtot = 0
      this.order.order_products.forEach(el => { subtot += el['Orderproducts'].price * el['Orderproducts'].quantity })
      this.order.order_collections.forEach(el => { subtot += el['Ordercollections'].price * el['Ordercollections'].quantity })
      return subtot.toFixed(2)
    },
    orderItems () {
      let items = []
      items = items.concat(this.order.order_products.map(el => ({ ...el, type: 'product' })))
      items = items.concat(this.order.order_collections.map(el => ({ ...el, type: 'collection' })))
      return items
    }
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/orders/items/${this.$route.params.id}`).then(response => {
        response.data.order_products.map((el) => {
          if (el['Orderproducts'].discount_type && el['Orderproducts'].discount_type !== 'bonused') {
            switch (el['Orderproducts'].discount_type) {
            case 'price':
              el['Orderproducts'].price += el['Orderproducts'].discount_amount
              el.name = `${el.name_tm} (-${el['Orderproducts'].discount_amount}m)`
              break
            case 'percentage':
              el['Orderproducts'].price += ((el['Orderproducts'].price * el['Orderproducts'].discount_amount) / (100 - el['Orderproducts'].discount_amount))
              el.name = `${el.name_tm} (-${el['Orderproducts'].discount_amount}%)`
              break
            case 'promo':
              el['Orderproducts'].price += ((el['Orderproducts'].price * el['Orderproducts'].discount_amount) / (100 - el['Orderproducts'].discount_amount))
              el.name = `${el.name_tm} (-${el['Orderproducts'].discount_amount}%)`
              break
            }
          } else if (el['Orderproducts'].discount_type && el['Orderproducts'].discount_type === 'bonused') {
            el.name = `${el.name_tm} (${el['Orderproducts'].discount_required}+${el['Orderproducts'].discount_bonus})`
          }
        })
        response.data.order_collections.map((el) => {
          if (el['Ordercollections'].discount_type && el['Ordercollections'].discount_type !== 'bonused') {
            switch (el['Ordercollections'].discount_type) {
            case 'price':
              el['Ordercollections'].price += el['Ordercollections'].discount_amount
              el.name = `${el.name_tm} (-${el['Ordercollections'].discount_amount}m)`
              break
            case 'percentage':
              el['Ordercollections'].price += ((el['Ordercollections'].price * el['Ordercollections'].discount_amount) / (100 - el['Ordercollections'].discount_amount))
              el.name = `${el.name_tm} (-${el['Ordercollections'].discount_amount}%)`
              break
            case 'promo':
              el['Ordercollections'].price += ((el['Ordercollections'].price * el['Ordercollections'].discount_amount) / (100 - el['Ordercollections'].discount_amount))
              el.name = `${el.name_tm} (-${el['Ordercollections'].discount_amount}%)`
              break
            }
          } else if (el['Ordercollections'].discount_type && el['Ordercollections'].discount_type === 'bonused') {
            el.name = `${el.name_tm} (${el['Ordercollections'].discount_required}+${el['Ordercollections'].discount_bonus})`
          }
        })
        this.order = response.data
        this.isLoading = false
        // this.$emit('setAppClasses', 'invoice-page')
      }).catch(() => { this.isLoading = false })
    },
    flaskPrint () {
      axios.post('http://127.0.0.1:5000', {
        order: {
          ...this.order,
          discount: this.discount,
          subtotal: this.subtotal,
          order_products: null,
          order_collections: null
        },
        items: this.orderItems
      }).then(() => this.notify('success', this.$t('notify.success'))).catch(() => this.notify('error', this.$t('notify.error')))
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    },
    printInvoice () { window.print() }
  },
  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
  }
}
</script>

<style lang="scss">
.not-data-table {
  display: none !important;
  // visibility: hidden !important ;
}
.invoice-page {
  height: auto !important;
}
@media print {
  html, body, * {
    height: auto !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    color: black;
  }
  .content-area-reduced, .content-wrapper, .router-view, .router-content, .content-area__content {
    height: auto !important;
  }
  h1, h3, h5, h6 {
    color: black !important;
  }
  h5 {
    line-height: 32px;
    padding-bottom: 12px;
  }
  body {
    height: min-content !important;
  }
  .invoice-page {
    height: auto !important;
    width: 100%;
    * {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      visibility: hidden;
      height: auto !important;
    }
    .not-data-table {
      display: none !important;
      visibility: hidden !important ;
    }
    .p-4 {
      padding: 5px 0 !important;
    }
    .vx-card__body {
      height: auto !important;
      padding: 0!important;
    }
    #content-area {
      margin: 0 !important;
    }
    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }
    #invoice-container {
      overflow: hidden;
      width: 55% !important;
      padding-right: 15px;
      border-radius: 0px;
      border-right: 1px dashed gray;
    }
    #second-invoice-container {
      display: block !important;
      width: 43% !important;
    }
    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #second-invoice-container,
    #second-invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
    #second-invoice-container {
      position: absolute;
      right: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
@page {
  size: auto;
}
</style>